// tslint:disable
/**
 * Issuer Email SES API
 * Issue an Email VC based on an Email challenge sent by AWS SES
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ConfigurationParameters {
  apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>)
  username?: string
  password?: string
  accessToken?: string | ((name?: string, scopes?: string[]) => string)
  basePath?: string
  baseOptions?: any
}

export class Configuration {
  /**
   * parameter for apiKey security
   * @param name security name
   * @memberof Configuration
   */
  apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>)
  /**
   * parameter for basic security
   *
   * @type {string}
   * @memberof Configuration
   */
  username?: string
  /**
   * parameter for basic security
   *
   * @type {string}
   * @memberof Configuration
   */
  password?: string
  /**
   * parameter for oauth2 security
   * @param name security name
   * @param scopes oauth2 scope
   * @memberof Configuration
   */
  accessToken?: string | ((name?: string, scopes?: string[]) => string)
  /**
   * override base path
   *
   * @type {string}
   * @memberof Configuration
   */
  basePath?: string
  /**
   * base options for axios calls
   *
   * @type {any}
   * @memberof Configuration
   */
  baseOptions?: any

  constructor(param: ConfigurationParameters = {}) {
    this.apiKey = param.apiKey
    this.username = param.username
    this.password = param.password
    this.accessToken = param.accessToken
    this.basePath = param.basePath
    this.baseOptions = param.baseOptions
  }
}
