// tslint:disable
/**
 * Issuer Email SES API
 * Issue an Email VC based on an Email challenge sent by AWS SES
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface ApiInitiatePayload
 */
export interface ApiInitiatePayload {
  /**
   *
   * @type {string}
   * @memberof ApiInitiatePayload
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ApiInitiatePayload
   */
  holder: string
  /**
   *
   * @type {Array<string>}
   * @memberof ApiInitiatePayload
   */
  type: Array<string>
  /**
   *
   * @type {ApiInitiatePayloadData}
   * @memberof ApiInitiatePayload
   */
  data: ApiInitiatePayloadData
}
/**
 *
 * @export
 * @interface ApiInitiatePayloadData
 */
export interface ApiInitiatePayloadData {
  /**
   *
   * @type {string}
   * @memberof ApiInitiatePayloadData
   */
  emailAddress: string
}
/**
 *
 * @export
 * @interface ApiVerifyPayload
 */
export interface ApiVerifyPayload {
  /**
   *
   * @type {string}
   * @memberof ApiVerifyPayload
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ApiVerifyPayload
   */
  holder: string
  /**
   *
   * @type {Array<string>}
   * @memberof ApiVerifyPayload
   */
  type: Array<string>
  /**
   *
   * @type {ApiVerifyPayloadData}
   * @memberof ApiVerifyPayload
   */
  data: ApiVerifyPayloadData
}
/**
 *
 * @export
 * @interface ApiVerifyPayloadData
 */
export interface ApiVerifyPayloadData {
  /**
   *
   * @type {string}
   * @memberof ApiVerifyPayloadData
   */
  code: string
}
/**
 * Initiate payload
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {ApiInitiatePayload}
   * @memberof InlineObject
   */
  payload: ApiInitiatePayload
}
/**
 * Verify payload
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {ApiVerifyPayload}
   * @memberof InlineObject1
   */
  payload: ApiVerifyPayload
}
/**
 * Successful response
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  status: string
  /**
   *
   * @type {Array<string>}
   * @memberof InlineResponse200
   */
  type: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse200
   */
  success: boolean
  /**
   *
   * @type {ApiInitiatePayloadData}
   * @memberof InlineResponse200
   */
  data: ApiInitiatePayloadData
}
/**
 * Successful response
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse2001
   */
  success: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof InlineResponse2001
   */
  type: Array<string>
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  status: string
  /**
   *
   * @type {ApiVerifyPayloadData}
   * @memberof InlineResponse2001
   */
  data: ApiVerifyPayloadData
  /**
   *
   * @type {Array<InlineResponse2001Vcs>}
   * @memberof InlineResponse2001
   */
  vcs: Array<InlineResponse2001Vcs>
}
/**
 *
 * @export
 * @interface InlineResponse2001Holder
 */
export interface InlineResponse2001Holder {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001Holder
   */
  id: string
}
/**
 *
 * @export
 * @interface InlineResponse2001Vcs
 */
export interface InlineResponse2001Vcs {
  /**
   *
   * @type {Array<object>}
   * @memberof InlineResponse2001Vcs
   */
  '@context': Array<object>
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001Vcs
   */
  id: string
  /**
   *
   * @type {Array<string>}
   * @memberof InlineResponse2001Vcs
   */
  type: Array<string>
  /**
   *
   * @type {InlineResponse2001Holder}
   * @memberof InlineResponse2001Vcs
   */
  holder: InlineResponse2001Holder
  /**
   *
   * @type {object}
   * @memberof InlineResponse2001Vcs
   */
  credentialSubject: object
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001Vcs
   */
  issuanceDate: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001Vcs
   */
  expirationDate?: string
  /**
   *
   * @type {InlineResponse2001Holder}
   * @memberof InlineResponse2001Vcs
   */
  revocation?: InlineResponse2001Holder
}
/**
 * Error response
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse400
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse400
   */
  status: string
  /**
   *
   * @type {Array<string>}
   * @memberof InlineResponse400
   */
  type: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse400
   */
  success: boolean
  /**
   *
   * @type {ApiInitiatePayloadData}
   * @memberof InlineResponse400
   */
  data: ApiInitiatePayloadData
}
/**
 * Error response
 * @export
 * @interface InlineResponse4001
 */
export interface InlineResponse4001 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse4001
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse4001
   */
  status: string
  /**
   *
   * @type {Array<string>}
   * @memberof InlineResponse4001
   */
  type?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse4001
   */
  success: boolean
  /**
   *
   * @type {ApiVerifyPayloadData}
   * @memberof InlineResponse4001
   */
  data: ApiVerifyPayloadData
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Send the user email address to Issuer Service to initiate the email VC issuance process
     * @summary SMS challenge token to end user
     * @param {InlineObject} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInitiatePost: async (body?: InlineObject, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/initiate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apikey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? await configuration.apiKey('apikey') : await configuration.apiKey
        localVarHeaderParameter['apikey'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return the code provided by the end user to prove they received the challenge
     * @summary VC generation
     * @param {InlineObject1} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVerifyPost: async (body?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/verify`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apikey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? await configuration.apiKey('apikey') : await configuration.apiKey
        localVarHeaderParameter['apikey'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Send the user email address to Issuer Service to initiate the email VC issuance process
     * @summary SMS challenge token to end user
     * @param {InlineObject} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiInitiatePost(
      body?: InlineObject,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiInitiatePost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Return the code provided by the end user to prove they received the challenge
     * @summary VC generation
     * @param {InlineObject1} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiVerifyPost(
      body?: InlineObject1,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiVerifyPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Send the user email address to Issuer Service to initiate the email VC issuance process
     * @summary SMS challenge token to end user
     * @param {InlineObject} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInitiatePost(body?: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
      return DefaultApiFp(configuration)
        .apiInitiatePost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return the code provided by the end user to prove they received the challenge
     * @summary VC generation
     * @param {InlineObject1} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiVerifyPost(body?: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
      return DefaultApiFp(configuration)
        .apiVerifyPost(body, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Send the user email address to Issuer Service to initiate the email VC issuance process
   * @summary SMS challenge token to end user
   * @param {InlineObject} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiInitiatePost(body?: InlineObject, options?: any) {
    return DefaultApiFp(this.configuration)
      .apiInitiatePost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return the code provided by the end user to prove they received the challenge
   * @summary VC generation
   * @param {InlineObject1} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiVerifyPost(body?: InlineObject1, options?: any) {
    return DefaultApiFp(this.configuration)
      .apiVerifyPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
